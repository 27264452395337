<template>
  <div>
    <a-card title="出库订单">
      <a-row :gutter="[16, 8]">
        <a-col :span="24" style="max-width: 256px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <!-- <a-col :span="24" style="max-width: 200px">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col> -->
        <a-col :span="24" style="max-width: 200px">
          <a-select v-model="searchForm.is_completed" placeholder="状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="false" value="false">进行中</a-select-option>
            <a-select-option key="true" value="true">已完成</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="max-width: 256px">
          <a-input v-model="searchForm.search" placeholder="订单编号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 260px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.fuzzy_search"
            style="border: 1px solid red; border-radius: 4px; margin: -1px 0"
            placeholder="模糊查询"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_upload" placeholder="上传状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="0" :value="true">已上传</a-select-option>
            <a-select-option key="1" :value="false">未上传</a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_print" placeholder="打印状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="0" :value="true">已打印</a-select-option>
            <a-select-option key="1" :value="false">未打印</a-select-option>
          </a-select>
        </a-col> -->
        <a-col :span="24" style="max-width: 110px">
          <a-button type="primary" icon="search" :loading="queryLoading" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" style="max-width: 450px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button @click="printDeliveryOrder">批量打印</a-button>
            <a-button @click="summaryExport">汇总导出</a-button>
          </a-button-group>
        </a-col>

        <a-col :span="24" style="max-width: 160px; float: right">
          <a-button type="primary" icon="plus" @click="addOrder">新增出库订单</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="dataItems"
          :pagination="pagination"
          :loading="queryLoading"
          :rowSelection="{ selectedRowKeys, onChange: onSelectChange }"
          :scroll="{ x: 1600, y: 480 }"
          @change="tableChange"
        >
          <template slot="external_number" slot-scope="value, item, index">
            <a @click="viewDetail(item)">{{ value }}</a>
          </template>
          <div slot="logistics_number" slot-scope="value, item">
            <span v-if="item.is_void">已作废</span>
            <span v-else-if="value && value.length > 0">{{ value }}</span>
            <span v-else-if="item.delivery_method === '线下配送'">{{ item.express }}</span>
            <span v-else style="color: crimson">待录入</span>
          </div>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="viewDetail(item)">详情</a-button>
              <a-button type="primary" @click="copyData(item)">复制</a-button>
              <a-popconfirm title="确定作废吗?" :disabled="item.is_void" @confirm="voidData(item)">
                <a-button type="danger" :disabled="item.is_void">作废</a-button>
              </a-popconfirm>
              <a-popconfirm title="确定作废重建吗?" :disabled="item.is_void" @confirm="rebuildData(item)">
                <a-button type="danger" :disabled="item.is_void">作废重建</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import {
  stockOutOrderList,
  deliveryOrderPrint,
  deliveryOrderTemplate,
  deliveryOrderImport,
  deliveryOrdersExport,
  stockOutOrderVoid,
  deliveryOrderSummaryExport,
} from "@/api/order";
import { warehouseOption } from "@/api/option";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import JsBarcode from "jsbarcode";
import NP from "number-precision";
import { exportExcel } from "@/utils/excel";

export default {
  components: {},
  data() {
    return {
      searchForm: { search: "", page: 1, page_size: 48, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      queryLoading: false,

      dataItems: [],
      warehouseItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "订单号",
          dataIndex: "external_number",
          width: 180,
          scopedSlots: { customRender: "external_number" },
        },
        {
          title: "状态",
          dataIndex: "status",
          width: 80,
          customRender: (value, item, index) => {
            return item.is_void ? "已作废" : item.is_completed ? "已完成" : "进行中";
          },
        },
        {
          title: "承运公司",
          dataIndex: "express",
          width: 100,
        },
        {
          title: "物流单号",
          dataIndex: "logistics_number",
          width: 160,
          scopedSlots: { customRender: "logistics_number" },
        },
        {
          title: "预计出库日期",
          dataIndex: "delivery_date",
          width: 120,
          customRender: (value) => {
            return value ? moment(value).format("YYYY-MM-DD") : "";
          },
        },
        {
          title: "收货人",
          dataIndex: "customer_contact",
          width: 120,
        },
        {
          title: "备注",
          dataIndex: "remark",
          width: 120,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 240,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],

      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      const searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.queryLoading = true;
      this.$router.push({ query: this.searchForm });
      stockOutOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.queryLoading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date) {
      const [startDate, endDate] = date;
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    addOrder() {
      this.$router.push({ path: "/order/stock_out_order_create" });
    },
    viewDetail(item) {
      this.$router.push({ path: "/order/stock_out_order_detail", query: { id: item.id } });
    },
    copyData(item) {
      this.$router.push({ path: "/order/stock_out_order_create", query: { id: item.id } });
    },
    voidData(item) {
      stockOutOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
    rebuildData(item) {
      stockOutOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.copyData(item);
      });
    },
    summaryExport() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的出库通知单!");
        return false;
      }
      deliveryOrderSummaryExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "出库通知单汇总");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的出库通知单!");
        return false;
      }
      deliveryOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "出库通知单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      deliveryOrderTemplate()
        .then((resp) => {
          exportExcel(resp, "出库通知单导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        deliveryOrderImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            this.$message.error(err.response.data.detail);
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    printDeliveryOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要打印的出库订单!");
        return;
      }

      const DeliveryPageCount = 14;
      deliveryOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("Delivery order");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (let deliveryOrderItem of data) {
          let pages = Math.ceil(deliveryOrderItem.delivery_material_items.length / DeliveryPageCount);

          let canvas = document.createElement("canvas");
          JsBarcode(canvas, deliveryOrderItem.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          let barcodeBase64 = canvas.toDataURL();

          let totalQuantity = 0;
          for (let item of deliveryOrderItem.delivery_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.total_quantity);
          }

          for (let index = 1; index <= pages; index++) {
            let deliveryMaterialItems = deliveryOrderItem.delivery_material_items.slice(
              (index - 1) * DeliveryPageCount,
              index * DeliveryPageCount
            );
            let tableRows = deliveryMaterialItems.map((item) => {
              return `
<tr>
  <td>${this.printText(item.material_item.number)}</td>
  <td>${this.printText(item.material_item.name)}</td>
  <td>${this.printText(item.material_item.english_name)}</td>
  <td>${this.printText(item.material_item.unit)}</td>
  <td>${this.printText(item.total_quantity)}</td>
</tr>`;
            });
            const deliveryDate = deliveryOrderItem.delivery_date
              ? moment(deliveryOrderItem.delivery_date).format("YYYY年 MM月 DD日")
              : "";

            const pageContent = `
<div style="padding: 16px">
  <div style="font-size: 24px; font-weight: 600; border-bottom: 3px solid">
    <span>NO.${deliveryOrderItem.number}</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="margin-top: 8px; font-size: 32px; font-weight: 600">
    <div style="width: 40%; margin: auto; border-bottom: 3px solid; display: flex">
      <div style="width: 33%; text-align: center">送</div>
      <div style="width: 34%; text-align: center">货</div>
      <div style="width: 33%; text-align: center">单</div>
    </div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 50%; text-align: left; font-size: 22px;">出库日期: ${deliveryDate}</div>
    <div style="width: 50%; text-align: center">订单号: ${this.printText(deliveryOrderItem.external_number)}</div>
  </div>

  <div style="margin-top: 8px; display: flex;">
    <div style="width: 50%; text-align: center; font-size: 24px; font-weight: 600;">
      <div style="padding-top: 8px">${deliveryOrderItem.client_item.name}</div>
    </div>
    
    <div style="width: 50%; text-align: center; ">
      <img src="${barcodeBase64}" />
    </div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 33%">收货人: ${this.printText(deliveryOrderItem.customer_contact)}</div>
    <div style="width: 34%">承运公司: ${this.printText(deliveryOrderItem.express)}</div>
    <div style="width: 33%">打包要求: ${this.printText(deliveryOrderItem.packaging_requirements)}</div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 33%">联系电话: ${this.printText(deliveryOrderItem.contact_phone)}</div>
    <div style="width: 67%">物流单号: ${this.printText(deliveryOrderItem.logistics_number)}</div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 100%"> 收货地址: ${this.printText(deliveryOrderItem.receiving_address)}</div>
  </div>

  <div style="margin-top: 8px; font-size: 14px; display: flex">
    <div style="width: 100%">备注: ${this.printText(deliveryOrderItem.remark)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th style="width: 25%">商品编号</th>
        <th style="width: 25%">商品名称</th>
        <th style="width: 30%">英文名称</th>
        <th style="width: 10%">单位</th>
        <th style="width: 10%">数量</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td colspan="2" style="text-align: left">合计: ${totalQuantity}</td>
        <td colspan="3" rowspan="2">
          为避免双方利益受损，针对以上项目请您在接受货物时务必同送货方进行确认，无误后再签收，签收后视为验收合格。货物完好.
        </td>
      </tr>
      <tr>
        <td colspan="2" style="text-align: left">总重量(KG):</td>
      </tr>
    </table>
  </div>

  <div style="margin-top: 16px; font-size: 14px; display: flex">
    <div style="width: 33%; border: 1px solid; padding: 4px">
      <div>发货人 （盖章/签字）</div>
      <div style="margin-top: 36px">发货日:</div>
    </div>
    <div style="width: 34%; border: 1px solid; padding: 4px; margin: 0 24px">
      <div>承运人 （盖章/签字）</div>
      <div style="margin-top: 36px">承运日:</div>
    </div>
    <div style="width: 33%; border: 1px solid; padding: 4px">
      <div>收货人 （盖章/签字）</div>
      <div style="margin-top: 36px">收货日:</div>
    </div>
  </div>
  
  <div style="margin-top: 16px; font-size: 14px; display: flex">
    <div style="width: 33%">创建时间: ${this.printText(deliveryOrderItem.create_time)}</div>
  </div>
</div>
            `;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
    this.list();

    warehouseOption({ page_size: 999999 }).then((data) => {
      this.warehouseItems = data.results;
    });
  },
};
</script>

<style scoped></style>
